<dialog class="
  bg-primary-1 my-auto mx-w-[90%] lg:max-w-[920px] w-full backdrop:bg-black/50 p-[20px] py-[50px] mx-auto md:p-[50px] lg:p-[95px]
">
  <img src="images/onewestmfg.com/popup-footer.webp" alt="" class="popup-background absolute bottom-0 left-0"/>
  
  <button id="closePopup" class="close text-white absolute right-ms2 md:right-ms3 top-ms-4 md:top-ms1 font-bold text-2xl cursor-pointer">✕</button>
  <div class="relative popup-content text-white">
    <img width="365" height="92" src="images/onewestmfg.com/OneWest_Logo_white_red_rgb.svg" alt="OneWest Manufacturing Partners logo" class="popup-logo mb-ms2 md:mb-ms5">
    <h3 class="mb-ms2">Westbrook Energy Group is now OneWest Manufacturing Partners</h3>
    <p class="mb-ms2">Formerly Westbrook Energy Group, we are now OneWest—a name that reflects our unity and clarity of purpose as a leading manufacturing partner for industries driving global progress. While our legacy remains foundational, this rebrand embodies our expanded capabilities and commitment to serving the energy, power, infrastructure, aerospace, marine, and defense industries with precision and agility.</p>
    <p>Our family of brands is equipped with the expertise and vertically integrated services needed to deliver on time, on spec, every time—empowering you to reliably deliver on your promise to customers.</p>
  </div>

  <div class="relative flex flex-wrap gap-ms0 justify-between mt-ms2 md:mt-ms6">
    <a class="button button--secondary flex leading-none hover:bg-primary-1 hover:text-white" href="https://www.onewestmfg.com/" target="_blank">Learn More About the Rebrand</a>
    <button class="button min-w-[160px] cursor-pointer" id="closePopup">Continue</button>
  </div>
</dialog>