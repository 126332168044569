import dialogHTML from "../../../modules/popup/default/index.html"
import logo from "../../../images/OneWest_Logo_white_red_rgb.svg";
import background from "../../../images/popup-footer.webp";
import css from "../../../../../dist/css/onewestmfg.com.min.css";

class OneWestPopup extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({mode: "open"});
  }

  connectedCallback() {
    const shadow = this.shadowRoot;
    const styleElement = document.createElement("style");
    styleElement.textContent = css;
    shadow.innerHTML = dialogHTML;
    shadow.prepend(styleElement);
    const logoElement = shadow.querySelector(".popup-logo");
    const backgroundElement = shadow.querySelector(".popup-background");
    logoElement.src = logo;
    backgroundElement.src = background;

    const dialog = shadow.querySelector("dialog");
    const buttons = shadow.querySelectorAll("button");
    dialog.showModal();

    buttons.forEach(button => {
      button.addEventListener("click", () => {
        dialog.close();
        setCookie("OneWestPopupSeen", "true", { expires: new Date(Date.now() + 86400000) });
      });
    });
  }
}

// Function to get a cookie by name
function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// Function to set a cookie
function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

// Function to create and show the popup
function showPopup() {
  customElements.define("onewest-popup", OneWestPopup);
  document.body.appendChild(document.createElement("onewest-popup"));
}

//Check if the cookie exists, if not, show the popup
if (!getCookie("OneWestPopupSeen")) {
  showPopup();
}
